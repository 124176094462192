import React from 'react';
import PropTypes from 'prop-types';
import styles from './ListContentHint.module.scss';

const ListContentHint = props => (
    <div id={props.id} className={styles.ListContentHint}>
        <div className={styles.textHolder}>
            <div className={styles.textHolderInner}>
                <div className={styles.textBox}>
                    {props.text}
                </div>
            </div>
        </div>
    </div>
);

ListContentHint.defaultProps = {
    text: 'Please do an action.',
    id: 'listcontenthint'
};

ListContentHint.propTypes = {
    text: PropTypes.string,
    id: PropTypes.string
};

export default ListContentHint;
