import React, { FunctionComponent } from 'react';
import {
    IconButton
} from '@mui/material';
import { Clear } from '@mui/icons-material';

interface Props {
    onClick: () => void
    // config: FormConfig,
    // formik: FormikContextType<any>,
    // size?: string
}

const DeleteFilterButton: FunctionComponent<Props> = ({ onClick }) => {
    console.log('AAA');
    return (
        <IconButton color="primary" component="label" onClick={onClick}>
            <Clear />
        </IconButton>
    );
};

export default DeleteFilterButton;
