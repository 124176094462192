import React from 'react';
import PropTypes from 'prop-types';
import styles from './Filter.module.scss';

class Filter extends React.Component {
    static propTypes = {
        children: PropTypes.any.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className={styles.Filter}>
                {this.props.children}
            </div>
        );
    }
}

export default Filter;
