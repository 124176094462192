import React from 'react';
import PropTypes from 'prop-types';
import Button20IconCircle from '../Buttons/Button20IconCircle/Button20IconCircle';
import styles from './TableOfContentsChapter.module.scss';

class TableOfContentsChapter extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        h1: PropTypes.string,
        h2: PropTypes.string,
        handleClick: PropTypes.func,
        selected: PropTypes.bool,
    };

    static defaultProps = {
        h1: 'Head',
        h2: 'Subhead',
        handleClick: key => alert(key + ' Clicked Chapter!'),
        selected: false
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleClick() {
        this.props.handleClick(this.props.id);
    }

    render() {
        return (
            <div id={'tableofcontentschapter-' + this.props.id} className={this.props.selected ? styles.active + ' ' + styles.TableOfContentsChapter : styles.TableOfContentsChapter}>
                <svg className={styles.svgBackground} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000"><polygon points="1984.5 1999.5 0.5 1999.5 0.5 0.5 1999.5 0.5 1999.5 1984.5 1984.5 1999.5" /></svg>
                <div
                    id={'tableofcontentschapter_open-' + this.props.id}
                    className={styles.inner}
                    onClick={this.handleClick.bind(this)}
                    role="presentation"
                >
                    <h1>{this.props.h1}</h1>
                    <h2>{this.props.h2}</h2>

                </div>
                <div className={styles.actions}>
                    <Button20IconCircle icon="edit" caption="EDIT" />
                    <Button20IconCircle icon="delete" caption="DELETE" />
                </div>
            </div>
        );
    }
}

export default TableOfContentsChapter;
