import React, { FunctionComponent } from 'react';
import i18n from 'i18next';
import {
    endOfMonth,
    startOfMonth,
    getMonth,
    addYears,
    addMonths,
    endOfYear,
    startOfYear,
    startOfQuarter,
    endOfQuarter,
    addQuarters
} from 'date-fns';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FormikContextType } from 'formik';
import { FormConfig } from '../Form';
import DatePickerFromTo from './DatePickerFromTo';

interface Props {
    config: FormConfig,
    formik: FormikContextType<any>,
    size?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fields: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const MyDate: FunctionComponent<Props> = ({ config, formik }) => {
    const [anchorElThis, setAnchorElThis] = React.useState<null | HTMLElement>(null);
    const [anchorElNext, setAnchorElNext] = React.useState<null | HTMLElement>(null);
    const [anchorElLast, setAnchorElLast] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const today = new Date();
    const fieldFrom = typeof config.field === 'string' ? config.field + 'From' : config.field.from;
    const fieldTo = typeof config.field === 'string' ? config.field + 'To' : config.field.to;
    const labelFrom = typeof config.label === 'string' ? config.field + 'From' : config.label.from;
    const labelTo = typeof config.label === 'string' ? config.field + 'To' : config.label.to;
    const hasErrorFrom = formik.errors[fieldFrom] !== undefined && formik.touched[fieldFrom] !== undefined;
    const hasErrorTo = formik.errors[fieldTo] !== undefined && formik.touched[fieldTo] !== undefined;
    const onChangeFrom = (date: any) => {
        formik.setFieldValue(fieldFrom, date);
    };
    const onChangeTo = (date: any) => {
        formik.setFieldValue(fieldTo, date);
    };
    const currentErrorFrom = formik.errors[fieldFrom];
    const currentErrorTo = formik.errors[fieldTo];
    const currentValueFrom = formik.values[fieldFrom];
    const currentValueTo = formik.values[fieldTo];
    if (currentValueFrom === undefined) {
        formik.setFieldValue(fieldFrom, new Date());
    }
    if (currentValueTo === undefined) {
        formik.setFieldValue(fieldTo, new Date());
    }
    if (currentValueFrom > currentValueTo) {
        formik.setFieldValue(fieldTo, currentValueFrom);
    }
    const setValues = (from: Date, to: Date) => {
        formik.setFieldValue(fieldFrom, from);
        formik.setFieldValue(fieldTo, to);
    };
    const handleCloseThis = () => {
        setAnchorElThis(null);
    };
    const handleClickThis = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElThis(event.currentTarget);
    };
    const handleCloseNext = () => {
        setAnchorElNext(null);
    };
    const handleClickNext = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElNext(event.currentTarget);
    };
    const handleCloseLast = () => {
        setAnchorElLast(null);
    };
    const handleClickLast = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElLast(event.currentTarget);
    };
    const lastMonth = () => {
        setValues(
            startOfMonth(addMonths(today, -1)),
            endOfMonth(addMonths(today, -1))
        );
        handleCloseLast();
    };
    const lastQuarter = () => {
        setValues(
            startOfQuarter(addQuarters(today, -1)),
            endOfQuarter(addQuarters(today, -1))
        );
        handleCloseLast();
    };
    const lastYear = () => {
        setValues(
            startOfYear(addYears(today, -1)),
            endOfYear(addYears(today, -1))
        );
        handleCloseLast();
    };
    const thisMonth = () => {
        setValues(
            startOfMonth(today),
            endOfMonth(today)
        );
        handleCloseThis();
    };
    const thisQuarter = () => {
        setValues(
            startOfQuarter(today),
            endOfQuarter(today)
        );
        handleCloseThis();
    };
    const thisYear = () => {
        setValues(
            startOfYear(today),
            endOfYear(today)
        );
        handleCloseThis();
    };
    const nextMonth = () => {
        setValues(
            startOfMonth(addMonths(today, 1)),
            endOfMonth(addMonths(today, 1))
        );
        handleCloseNext();
    };
    const nextQuarter = () => {
        setValues(
            startOfQuarter(addQuarters(today, 1)),
            endOfQuarter(addQuarters(today, 1))
        );
        handleCloseNext();
    };
    const nextYear = () => {
        setValues(
            startOfYear(addYears(today, 1)),
            endOfYear(addYears(today, 1))
        );
        handleCloseNext();
    };
    const { size, showLabel, suggestions } = config.appearance;
    return (
        <div>
            <div className={classes.fields}>
                <DatePickerFromTo config={config} formik={formik} />
            </div>
            {suggestions
            && (
            <div className={classes.buttons}>
                <ButtonGroup color="primary" size="small" aria-label="small text primary button group">
                    <Button onClick={handleClickLast}>{i18n.t('Last')}</Button>
                    <Button onClick={handleClickThis}>{i18n.t('This')}</Button>
                    <Button onClick={handleClickNext}>{i18n.t('Next')}</Button>
                </ButtonGroup>
                <Menu
                    anchorEl={anchorElLast}
                    keepMounted
                    open={Boolean(anchorElLast)}
                    MenuListProps={{ onMouseLeave: handleCloseLast }}
                    onClose={handleCloseLast}
                >
                    <MenuItem onClick={lastMonth}>{i18n.t('Last Month')}</MenuItem>
                    <MenuItem onClick={lastQuarter}>{i18n.t('Last Quarter')}</MenuItem>
                    <MenuItem onClick={lastYear}>{i18n.t('Last Year')}</MenuItem>
                </Menu>
                <Menu
                    anchorEl={anchorElThis}
                    keepMounted
                    open={Boolean(anchorElThis)}
                    MenuListProps={{ onMouseLeave: handleCloseThis }}
                    onClose={handleCloseThis}
                >
                    <MenuItem onClick={thisMonth}>{i18n.t('This Month')}</MenuItem>
                    <MenuItem onClick={thisQuarter}>{i18n.t('This Quarter')}</MenuItem>
                    <MenuItem onClick={thisYear}>{i18n.t('This Year')}</MenuItem>
                </Menu>
                <Menu
                    anchorEl={anchorElNext}
                    keepMounted
                    open={Boolean(anchorElNext)}
                    MenuListProps={{ onMouseLeave: handleCloseNext }}
                    onClose={handleCloseNext}
                >
                    <MenuItem onClick={nextMonth}>{i18n.t('Next Month')}</MenuItem>
                    <MenuItem onClick={nextQuarter}>{i18n.t('Next Quarter')}</MenuItem>
                    <MenuItem onClick={nextYear}>{i18n.t('Next Year')}</MenuItem>
                </Menu>
            </div>
)}
        </div>
    );
};

export default MyDate;
