import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import i18n from 'i18next';
import 'react-table/react-table.css';
import Button from './Button';
import styles from './css/TableSelector.module.scss';
import './css/react-table-custom.scss';
import TestId from '../../../utils/TestId';

const prepareColumns = columns => {
    const newColumns = columns.slice(0);
    newColumns.forEach(column => {
        column.className = styles.td;
    });
    newColumns.push({
        id: 'selected',
        Header: '',
        accessor: 'selected',
        filterable: false,
        Cell: cellProps => <span className={cellProps.original.selected ? styles.checked : styles.unchecked}>{cellProps.original.selected ? 'true' : 'false'}</span>,
        width: 30,
        style: { textAlign: 'right', padding: 0 },
        sortable: false,
    });
    return newColumns;
};

class TableSelector extends React.Component {
    static propTypes = {
        idIndex: PropTypes.string,
        data: PropTypes.array,
        columns: PropTypes.array,
        callback: PropTypes.func,
        id: PropTypes.string,
        defaultPageSize: PropTypes.number,
        showPageSizeOptions: PropTypes.bool,
    }

    static defaultProps = {
        idIndex: 'id',
        data: [
            {
                id: 1,
                name: 'Vanille',
                available: 2,
                vagan: 'Nein',
                vegetarian: 'Nein'
            },
            {
                id: 2,
                name: 'Schoko',
                available: 4,
                vagan: 'Ja',
                vegetarian: 'Nein'
            },
            {
                id: 3,
                name: 'Erdbeer',
                available: 10,
                vagan: 'Ja',
                vegetarian: 'Ja'
            }
        ],
        columns: [
            {
                Header: 'Sorte',
                accessor: 'name',
            }, {
                Header: 'Lagerbestand',
                accessor: 'available',
            }, {
                Header: 'Vegetarisch',
                accessor: 'vegetarian',
            }, {
                Header: 'Vegan',
                accessor: 'vagan',
            }
        ],
        callback: ids => { console.log(ids); },
        id: null,
        defaultPageSize: 20,
        showPageSizeOptions: true,
    }

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            dataSelected: [],
            filtered: [],
        };
        this.selectAll = this.selectAll.bind(this);
    }

    setSelected(id, force = false, forceType = true) {
        // let data = [];
        const { data } = this.state;
        const dataSelected = [];
        data.forEach(elem => {
            if (elem[this.props.idIndex] === id) {
                if (force) {
                    elem.selected = forceType;
                } else {
                    elem.selected = !elem.selected;
                }
            }
            if (elem.selected) {
                dataSelected.push(elem[this.props.idIndex]);
            }
        });
        this.setState({ data, dataSelected });
        this.props.callback(dataSelected);
    }

    selectAll(forceType) {
        this.selectTable.getResolvedState().sortedData.forEach(elem => {
            this.setSelected(elem._original[this.props.idIndex], true, forceType);
        });
    }

    render() {
        return (
            <div className={styles.TableSelector} id={TestId('tableselector', this.props.id)}>
                <div className={styles.row}>
                    <div className={styles['col-12']}>
                        <ReactTable
                            data={this.state.data}
                            className="-striped -highlight"
                            columns={prepareColumns(this.props.columns)}
                            filterable
                            ref={r => {
                                this.selectTable = r;
                            }}
                            minRows={1}
                            defaultPageSize={this.props.defaultPageSize}
                            showPageSizeOptions={this.props.showPageSizeOptions}
                            onFilteredChange={filtered => { this.setState({ filtered }); }}
                            filtered={this.state.filtered}
                            getTdProps={(state, rowInfo) => {
                                if (typeof rowInfo !== 'undefined') {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            this.setSelected(rowInfo.row._original[this.props.idIndex]);
                                            if (handleOriginal) {
                                                handleOriginal();
                                            }
                                        }
                                    };
                                }
                                return false;
                            }}
                            getTrProps={(state, rowInfo) => {
                                if (typeof rowInfo !== 'undefined') {
                                    return {
                                        className: rowInfo.row._original.selected ? 'selected' : styles.unselected
                                    };
                                }
                                return false;
                            }}
                            previousText={i18n.t('tableSelector.ReactTable.previousText')}
                            nextText={i18n.t('tableSelector.ReactTable.nextText')}
                            loadingText={i18n.t('tableSelector.ReactTable.loadingText')}
                            noDataText={i18n.t('tableSelector.ReactTable.noDataText')}
                            pageText={i18n.t('tableSelector.ReactTable.pageText')}
                            ofText={i18n.t('tableSelector.ReactTable.ofText')}
                            rowsText={i18n.t('tableSelector.ReactTable.rowsText')}
                            pageJumpText={i18n.t('tableSelector.ReactTable.pageJumpText')}
                            rowsSelectorText={i18n.t('tableSelector.ReactTable.rowsSelectorText')}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles['col-10']}>
                        <Button size="auto" caption={i18n.t('tableSelector.buttons.selectall')} handleClick={() => { this.selectAll(true); }} />
                        <Button size="auto" caption={i18n.t('tableSelector.buttons.deselectall')} handleClick={() => { this.selectAll(false); }} />
                        <Button size="auto" caption={i18n.t('tableSelector.buttons.resetfilters')} handleClick={() => this.setState({ filtered: [] })} />
                    </div>
                    <div className={styles['col-2'] + ' ' + styles.right}>
                        <div className={styles.elementsSelected}>
                            {this.state.dataSelected.length}
                            {' '}
                            {this.state.dataSelected.length === 1 ? i18n.t('tableSelector.buttons.selected.single') : i18n.t('tableSelector.buttons.selected.multiple')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TableSelector;
