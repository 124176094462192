/* eslint-disable camelcase */


interface Financialyear {
    month: string;
    day: string;
}

interface Company {
    financialyear: Financialyear;
}

interface ModuleSettings {
    endpointUrl: string;
    jsUrl: string;
    frontendCache?: boolean;
}

interface Modules {
    crossanalytics: ModuleSettings;
    editor: ModuleSettings;
}

interface ClientConfig {
    forceHttps: boolean;
    saml: boolean;
    newReportView: boolean;
    endpointUrl: string;
    modules: Modules;
    company: Company;
}

declare interface MyProps {
    config: any;
}
declare type MyExtendedWindow = typeof window & MyProps;

class Globals {
    version: string;

    baseUrl: string;

    rootUrl: string;

    baseLangs: string;

    user: any;

    actions: any;

    schemaUserModel: any;

    saml: Boolean;

    i18n: any;

    serverConfig: {
        reportjob_save_path?: string;
        version?: string;
        application_langs?: string;
    };

    nextPath: string;

    modules: any;

    clientConfig: ClientConfig;

    interfacerunView: any;

    constructor() {
        this.baseUrl = 'http://127.0.0.1:9001/api/';
        const myLocation = window.location as any;
        if (typeof myLocation.origin === 'undefined') {
            myLocation.origin = myLocation.protocol + '//' + myLocation.host;
        }
        this.rootUrl = myLocation.origin;
        this.saml = false;
        const myWindow = window as MyExtendedWindow;
        const { config } = myWindow;
        this.clientConfig = config;
        if (typeof config !== 'undefined') {
            this.saml = config.saml !== undefined ? config.saml : false;
            if (typeof config.endpointUrl !== 'undefined') {
                this.baseUrl = config.endpointUrl;
                if (this.baseUrl.indexOf('/', 0) === 0) {
                    this.baseUrl = this.rootUrl + this.baseUrl;
                } else if (this.baseUrl.indexOf(':', 0) === 0) {
                    this.baseUrl = myLocation.protocol + '//' + myLocation.hostname + this.baseUrl;
                }
            }
            if (typeof config.modules !== 'undefined') {
                this.modules = config.modules;
                Object.keys(this.modules).forEach(key => {
                    if (typeof this.modules[key].endpointUrl !== 'undefined') {
                        if (this.modules[key].endpointUrl.indexOf('/', 0) === 0) {
                            this.modules[key].endpointUrl = this.rootUrl + this.modules[key].endpointUrl;
                        } else if (this.modules[key].endpointUrl.indexOf(':', 0) === 0) {
                            this.modules[key].endpointUrl = myLocation.protocol + '//' + myLocation.hostname + this.modules[key].endpointUrl;
                        }
                    }
                });
            }
            if (config.company === undefined) {
                config.company = {
                    financialyear: {
                        month: '04',
                        day: '15',
                    },
                };
            }
        }
        this.user = null;
        this.actions = null;
        this.version = '';
        this.baseLangs = '';
        this.serverConfig = {};
        this.schemaUserModel = null;
        this.nextPath = '';
        this.i18n = null;
        this.i18n = {
            _: (test: string) => test,
        };
        this.interfacerunView = null;
    }
}
const globals = new Globals();

export default globals;

export { Globals };
