import React from 'react';
import PropTypes from 'prop-types';
import styles from './Label.module.scss';

class FormInput extends React.Component {
    static propTypes = {
        labelwidth: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string
    }

    static defaultProps = {
        labelwidth: '8',
        label: 'Foo',
        name: 'foo'
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={styles['col-' + this.props.labelwidth]}>
                <label className={styles.label} htmlFor={this.props.name}>
                    {this.props.label}
                    :
                </label>
            </div>
        );
    }
}

export default FormInput;
