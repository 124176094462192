import React, { FunctionComponent, ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import { FormikContextType } from 'formik';
import Autocomplete from '@material-ui/core/Autocomplete';
import { CircularProgress } from '@material-ui/core';
import { FormConfig, FormConfigOption, useOptions } from '../Form';


interface Props {
    config: FormConfig,
    formik: FormikContextType<any>,
}

const MyAutocomplete:FunctionComponent<Props> = ({ config, formik }) => {
    const field = config.field as string;
    const hasError = formik.errors[field] !== undefined && formik.touched[field] !== undefined;
    const onChange = (evt:ChangeEvent<{}>, value: FormConfigOption | null) => {
        formik.setFieldValue(field, value ? value.value : '');
    };
    if (config.options == null) {
        config.options = [];
    }
    const { loading, options } = useOptions(formik, config);
    return (
        <>
            {!loading
                ? (
                    <>
                        <Autocomplete
                            options={options}
                            getOptionLabel={(option: FormConfigOption) => option.label}
                            onChange={onChange}
                            onBlur={formik.handleBlur}
                            renderInput={(params:any) => (
                                <TextField
                                    error={hasError}
                                    helperText={hasError ? formik.errors[field] : ''}
                                    name={config.field}
                                    defaultValue={config.defaultValue}
                                    label={config.label}
                                    variant="outlined"
                                    {...params}
                                />
                            )}
                        />
                    </>
                )
                : <CircularProgress />
            }
        </>
    );
};

export default MyAutocomplete;
