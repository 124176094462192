import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Theme
} from '@material-ui/core';
import { FormConfig, ExtendFormik } from './Form';
import {
    Autocomplete, Text, Select, Date, DateFromTo, TimeSelect
} from './Fields';

export type FormProps = {
    config: FormConfig[],
    form: ExtendFormik
}

const useStyles = makeStyles((theme:Theme) => ({
    fields: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}));

const FormContainer:FunctionComponent<FormProps> = ({ config, form }) => {
    const classes = useStyles();
    const getField = (aConfig:FormConfig) => {
        switch (aConfig.type) {
                        case 'time':
                            return <TimeSelect config={aConfig} formik={form} />;
                        case 'dateFromTo':
                        case 'date_from_to':
                            return <DateFromTo config={aConfig} formik={form} />;
                        case 'date':
                        case 'date_singleday':
                            return <Date config={aConfig} formik={form} />;
                        case 'select':
                        case 'combobox':
                            return <Select config={aConfig} formik={form} />;
                        case 'autocomplete':
                        case 'multiselect':
                            return <Autocomplete config={aConfig} formik={form} />;
                        case 'text':
                            return <Text config={aConfig} formik={form} />;
                        case 'number':
                            return <Text config={aConfig} formik={form} />;
                        case 'array':
                            return (
                                <FormContainer config={aConfig.children || []} form={form} />
                            );
                        case 'tableselect':
                        case 'multiselect_cols':
                        default:
                            return <div>Unsupported field</div>;
        }
    };

    const builder = (aConfig:FormConfig) => (
        <div key={aConfig.field as string} className={classes.fields}>
            { getField(aConfig) }
        </div>
    );

    return (
        <>
            {config.map(c => builder(c))}
        </>
    );
};

export default FormContainer;
