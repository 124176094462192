import React from 'react';
import PropTypes from 'prop-types';
import styles from './ButtonFilter.module.scss';

class ButtonFilter extends React.Component {
    static propTypes = {
        caption: PropTypes.string,
        handleClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        caption: 'Button'
    };

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <a className={styles.ButtonFilter} role="presentation" title={this.props.caption} onClick={() => this.props.handleClick()}>
                {this.props.caption}
            </a>
        );
    }
}

export default ButtonFilter;
