import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './TableStandard.module.scss';

function typeConverter(field, id, value) {
    switch (field.type) {
                    case 'date':
                        if (field.format) {
                            return moment(value, 'YYYY-MM-DD HH:mm:ss').format(field.format);
                        }
                        return moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm');
                    case 'actions':
                        return field.buttons(id) || '';
                    default:
                        return value;
    }
}

class TableStandard extends React.Component {
    static propTypes = {
        orderDir: PropTypes.string,
        orderBy: PropTypes.number,
        fields: PropTypes.array,
        data: PropTypes.array
    };

    static defaultProps = {
        orderDir: 'ASC',
        orderBy: 0,
        fields: [
            {
                id: 'date', label: 'Datum', type: 'date', style: {}
            },
            {
                id: 'name', label: 'Name', type: 'string', style: { width: '100%' }
            },
            {
                id: 'user', label: 'Benutzer', type: 'string', style: {}
            },
            {
                id: 'action', label: 'Aktion', type: 'actions', style: {}
            }

        ],
        data: [
            { date: '2016-01-09 13:12:00', name: 'Foo', user: 'Bernard' },
            { date: '2016-01-09 13:12:01', name: 'Bar', user: 'Felix' },
            { date: '2016-01-09 13:12:01', name: 'Baz', user: 'Xaver' }
        ]
    };

    constructor(props) {
        super(props);
        this.state = {
            orderDir: this.props.orderDir,
            orderBy: this.props.orderBy,
            data: this.props.data
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            data: props.data
        });
    }

    sort() {
        this.state.data.sort((indexA, indexB) => {
            const valueA = indexA[this.state.orderBy];
            const valueB = indexB[this.state.orderBy];
            let sortVal = 0;
            if (valueA > valueB) {
                sortVal = 1;
            }
            if (valueA < valueB) {
                sortVal = -1;
            }
            if (sortVal !== 0 && this.state.orderDir === 'DESC') {
                sortVal *= -1;
            }
            return sortVal;
        });
    }

    handleSort(index) {
        // eslint-disable-next-line react/no-access-state-in-setstate
        let newDir = this.state.orderDir;
        if (this.state.orderBy === index) {
            switch (this.state.orderDir) {
                            case 'ASC':
                                newDir = 'DESC';
                                break;
                            default:
                                newDir = 'ASC';
            }
        }
        this.setState({ orderBy: index, orderDir: newDir });
    }

    detectSort(index) {
        if (this.state.orderBy === index) {
            return this.state.orderDir;
        }
        return false;
    }


    render() {
        /* const optionsBy = [];
        for (let i = 0; i < this.state.fields.length; i += 1) {
            const actualElem = { value: i, label: this.state.fields[i].label };
            optionsBy.push(actualElem);
        } */

        this.sort();

        const colnames = this.props.fields.map(field => {
            if (field.type !== 'id') {
                if (field.type !== 'actions') {
                    return (<th key={field.id} className={styles[field.type] + ' ' + styles.orderby + ' ' + styles[this.detectSort(field.id)]} onClick={() => this.handleSort(field.id)}>{field.label}</th>);
                }
                return (<th key={field.id} className={styles[field.type] + ' ' + styles[this.detectSort(field.id)]}>{field.label}</th>);
            }
            return null;
        });
        const lines = this.state.data.map(col => (
            <tr key={col.id}>
                {this.props.fields.map(field => (field.type !== 'id'
                    ? (
                        <td key={field.id} className={styles[field.type] + ' ' + styles[this.detectSort(field.id)]} style={field.style}>
                            {typeConverter(field, col.id, col[field.id])}
                        </td>
) : null
                ))}
            </tr>
        ));

        return (
            <div className={styles.TableStandard}>
                <table>
                    <thead>
                        <tr>
                            {colnames}
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TableStandard;
