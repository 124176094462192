import React, { FunctionComponent, ChangeEvent, useEffect } from 'react';
import {
    Stepper, Step, StepLabel, Button, Typography, Paper, Theme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import i18n from 'i18next';
import Form, {
    FormConfig, useForm, ExtendFormik, FormConfigOption, Field
} from './Form';
import FormContainer from './FormContainer';


const useStyles = makeStyles((theme: Theme) => {
    const mr = theme.breakpoints.up(600 + (parseInt(theme.spacing(2), 10) * 2));
    return ({
        root: {
            width: 'auto',
            textAlign: 'left',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [mr]: {
                width: 700,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
        },
        paper: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(600 + parseInt(theme.spacing(3), 10) * 2)]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            },
        },
        stepper: {
            paddingRight: '50px'
        },
        button: {
            marginRight: theme.spacing(1),
        },
        name: {
            width: '100%'
        },
        nameContainer: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        contentContainer: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(2)
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });
});

export interface FormStep {
    title?: string;
    content?: string;
    formConfig?: FormConfig[];
    form?: ExtendFormik;
    optional?: boolean;
}

export interface StepperProps {
    steps: FormStep[],
    defaultName?: string,
    itemLabelOnName?: boolean;
    labelDefaultName?: boolean;
    onSubmit?: (values: any, actions: any) => void
}

const FormStepper: FunctionComponent<StepperProps> = ({
    steps, defaultName, onSubmit, itemLabelOnName = false, labelDefaultName = true
}) => {
    const classes = useStyles();
    const [nameChanged, setNameChanged] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [name, setName] = React.useState<String>();
    const [open, setOpen] = React.useState(true);
    const step = steps[activeStep];
    const isStepOptional = (astep: number) => step.optional;

    const isStepSkipped = (astep: number) => skipped.has(step);

    const isLastStep = activeStep === steps.length - 1;

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(prevSkipped => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const nameOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setNameChanged(true);
        setName(evt.target.value);
    };

    const changeName = (val: any) => {
        if (!nameChanged) {
            let newName = labelDefaultName ? '_' + defaultName : '';
            for (const [key, value] of Object.entries(val)) {
                const findEntry = (aitem:any): boolean => {
                    const { field, type } = aitem!;
                    if (typeof (field) === 'string') {
                        return field === key;
                    } if (type === 'dateFromTo' || type === 'date_from_to') {
                        return field.from === key || field.to === key;
                    }
                };
                const item = steps.flatMap(fitem => fitem.formConfig).find(aitem => findEntry(aitem));
                let itemValue = value;
                console.log(key, value, steps.flatMap(fitem => fitem.formConfig));
                if (item && itemValue) {
                    const itemName = item.label && item.label !== '' ? item.label : item.field;
                    let isFirst = false;
                    switch (item.type) {
                                    case 'date_from_to':
                                    case 'dateFromTo':
                                        itemValue = format(itemValue as Date, 'dd.MM.yyyy');
                                        isFirst = (item.field as Field).from === key;
                                        if (itemLabelOnName) {
                                            newName += (isFirst ? '_' : '-') + `${itemName}(${itemValue})`;
                                        } else {
                                            newName += (isFirst ? '_' : '-') + itemValue;
                                        }
                                        break;
                                    case 'date_singleday':
                                    case 'date':
                                        itemValue = format(itemValue as Date, 'dd.MM.yyyy');
                                        if (itemLabelOnName) {
                                            newName += `_${itemName}(${itemValue})`;
                                        } else {
                                            newName += `_${itemValue}`;
                                        }
                                        break;
                                    case 'combobox':
                                    case 'autocomplete':
                                    case 'multiselect':
                                    case 'select':
                                        if (item.defaultOptions) {
                                            console.log(item.defaultOptions);
                                            itemValue = item.options ? item.defaultOptions.find(m => m.value === value)?.label || value : value;
                                        }
                                        if (itemLabelOnName) {
                                            newName += `_${itemName}(${itemValue})`;
                                        } else {
                                            newName += `_${itemValue}`;
                                        }
                                        break;
                                    default:
                                        if (itemLabelOnName) {
                                            newName += `_${itemName}(${itemValue})`;
                                        } else {
                                            newName += `_${itemValue}`;
                                        }
                    }
                } else if (itemValue) {
                    newName += `_${itemValue}`;
                }
            }
            setName(newName.substring(1));
        }
    };

    useEffect(() => {
        setName(defaultName);
    }, [defaultName]);

    const form = useForm({
        config: step ? step.formConfig : undefined,
        onSubmit: (values: any, actions: any) => {
            if (isLastStep) {
                values.documentname = name;
                console.log(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
                if (onSubmit) {
                    onSubmit(values, actions);
                }
            } else {
                setActiveStep(activeStep + 1);
                actions.setTouched({});
                actions.setSubmitting(false);
            }
        },
        validate: (val2: any) => {
            // changeName(val2);
        }
    });

    useEffect(() => {
        changeName(form.values);
    }, [form.values]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <Dialog fullWidth={false} maxWidth="md" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <form className={classes.root} onSubmit={form.handleSubmit}>
                    <MuiDialogTitle className={classes.root}>
                        <Stepper className={classes.stepper} activeStep={activeStep}>
                            {steps.map((astep, index) => {
                                const stepProps: {
                                    completed?: any;
                                } = {};
                                const labelProps: {
                                    optional?: any;
                                } = {};
                                if (isStepOptional(index)) {
                                    labelProps.optional = <Typography variant="caption">Optional</Typography>;
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={astep.title} {...stepProps}>
                                        <StepLabel {...labelProps}>{astep.title}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>
                    <MuiDialogContent dividers>
                        <div>

                            {activeStep === steps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>
                                        {i18n.t('formstepper.finish.message')}
                                    </Typography>
                                </div>
                            ) : (
                                <div>
                                    <div className={classes.nameContainer}>
                                        <TextField
                                            className={classes.name}
                                            label={i18n.t('formstepper.reportname')}
                                            name="documentname"
                                            value={name}
                                            onChange={nameOnChange}
                                        />
                                    </div>
                                    <div className={classes.contentContainer}>
                                        {step.content}
                                    </div>
                                    <div>
                                        {step.formConfig
                                            && (
                                                <FormContainer config={step.formConfig} form={form} />
                                            )
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                            {i18n.t('formstepper.back')}
                        </Button>
                        {isStepOptional(activeStep) && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSkip}
                                className={classes.button}
                            >
                                {i18n.t('formstepper.skip')}
                            </Button>
                        )}

                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.button}
                        >
                            {isLastStep ? i18n.t('formstepper.finish') : i18n.t('formstepper.next')}
                        </Button>
                    </MuiDialogActions>
                </form>
            </Dialog>
        </div>
    );
};
export default FormStepper;
