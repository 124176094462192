import React from 'react';
import PropTypes from 'prop-types';
// import 'react-select/dist/react-select.css';
import Select from 'react-select';
import _ from 'underscore';
import Utils from '../../utils/Utils';
import '../../css/react-select.css';
// import './css/formelements.scss';

class Field extends React.Component {
    static propTypes = {
        value: PropTypes.any,
        multi: PropTypes.bool,
        clearable: PropTypes.bool,
        name: PropTypes.string,
        labelKey: PropTypes.string,
        valueKey: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        arrowRenderer: PropTypes.any,
        options: PropTypes.array,
        multiValues: PropTypes.bool
    };

    static defaultProps = {
        value: 'foo',
        multi: false,
        clearable: true,
        name: 'foo',
        labelKey: 'label',
        valueKey: 'value',
        arrowRenderer: false,
        multiValues: false,
        options: []
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            options: props.options
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(props) {
        const newState = {};
        if (props.value !== undefined) {
            newState.value = props.value;
        }
        if (props.options !== undefined) {
            newState.options = props.options;
        }
        if (!_.isEmpty(newState)) {
            this.setState(newState);
        }
    }

    handleChange(val) {
        // const validation = this.validate(event.target.value);
        // this.setState({
        // value: val
        // validationError: validation.validationErrors
        // });
        if (this.props.multiValues) {
            const { options, value } = this.state;
            const newOptions = [];
            let fEle = null;
            let add = false;
            let setState = true;
            if (val.length > value.length) {
                const addVal = val[val.length - 1];
                fEle = addVal.value;
                add = true;
            } else {
                value.some(vval => {
                    if (_.findWhere(val, { value: vval }) === undefined) {
                        fEle = vval;
                        return true;
                    }
                    return false;
                });
            }
            options.some(option => {
                if (option.value === fEle) {
                    if (option.noMultiValue !== undefined && option.noMultiValue) {
                        setState = false;
                        return true;
                    }
                    if (add) {
                        newOptions.push(option);
                        const optionNew = _.clone(option);
                        optionNew.value = optionNew.value + '||' + Utils.guid();
                        newOptions.push(optionNew);
                    }
                } else {
                    newOptions.push(option);
                }
                return false;
            });
            if (setState) {
                this.setState({ options: newOptions });
            }
        }
        this.props.onChange(val, this.props.name, false);
    }

    render() {
        return (
            <div>
                {this.props.multi === true
                    ? (
                        <Select
                            arrowRenderer={this.props.arrowRenderer}
                        // name={this.props.name}
                            value={this.state.value}
                            options={this.state.options}
                            labelKey={this.props.labelKey}
                            valueKey={this.props.valueKey}
                        /* onChange={val => { this.setState({ value: val }); this.props.onChange(val); }} */
                            onChange={val => this.handleChange(val)}
                            placeholder="..."
                            clearable={this.props.clearable}
                            removeSelected
                            onValueClick={val => console.log(val)}
                            multi={this.props.multi}
                        />
)
                        : (
                            <Select
                        // name={this.props.name}
                                value={this.state.value}
                                options={this.state.options}
                                onChange={val => { /* this.setState({ value: val }); */ this.handleChange(val); /* this.props.onChange(val); */ }}
                                labelKey={this.props.labelKey}
                                valueKey={this.props.valueKey}
                                placeholder="..."
                                clearable={this.props.clearable}
                                multi={this.props.multi}
                            />
)}
            </div>
        );
    }
}

export default Field;
