import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import i18n from 'i18next';
import { Link as UiLink } from '@material-ui/core';
import Globals from '../../utils/Globals';
import styles from './Document.module.scss';

class Document extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        enableHelp: PropTypes.func,
        linkToInfo: PropTypes.bool
    };

    static defaultProps = {
        children: '',
        linkToInfo: true,
        enableHelp: null
    };

    constructor(props) {
        super(props);
        this.historyCount = 1;
        // this.state = {
        //     historyCount: 1
        // };
        this.userName = 'maBinso Software GmbH';
        this.version = '#dev';
        if (Globals.user !== null) {
            this.userName = (Globals.user.get('firstname') ? Globals.user.get('firstname') : '') + ' ' + (Globals.user.get('lastname') ? Globals.user.get('lastname') : '');
            if (this.userName === '' || this.userName === ' ') {
                this.userName = Globals.user.get('username');
            }
        }

        if (Globals.serverConfig !== null) {
            this.version = Globals.serverConfig.version;
        }

        // this.onClose = this.onClose.bind(this);
    }

    static onClose = that => {
        window.history.go(that.historyCount * -1);
    };

    historyCounter = that => {
        that.historyCount += 1;
    };

    render() {
        const { linkToInfo, enableHelp, children } = this.props;
        return (
            <div className={styles.documentHolder} id="documentheader">
                <div className={styles.documentHead}>
                    <div className={styles.documentTitle} />
                    <div className={styles.studioInfo}>
                        <div className={styles.version}>
                            maBinso Studio
                            {this.version}
                            {' '}
                            { linkToInfo
                                && <Link to="/info">{ i18n.t('licence.header') }</Link>
                            }
                        </div>
                        <div className={styles.username}>{this.userName}</div>
                        { enableHelp !== null
                            && (
                                <UiLink onClick={enableHelp}>
                                    <HelpOutlineIcon style={{ color: 'white', margin: '0px 0px -7px 10px' }} fontSize="large" />
                                </UiLink>
                            )
                        }
                    </div>
                    <a id="documentheader_close" role="presentation" className={styles.documentClose} onClick={() => { Document.onClose(this); }}>&times;</a>
                </div>
                <div className={styles.documentContent}>
                    <div className={styles.documentContentInner}>
                        {
                        React.Children.map(children, child => React.cloneElement(child, { historyCounter: this.historyCounter, parent: this }))
                    }
                    </div>
                </div>
            </div>
        );
    }
}
export default Document;
