/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { SliderPicker } from 'react-color';
import ButtonText from '../Buttons/ButtonText/ButtonText';
import Label from './Label';
import styles from './ColorPicker.module.scss';

class ColorPicker extends React.Component {
    static propTypes = {
        labelwidth: PropTypes.string,
        inputwidth: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        color: PropTypes.string,
        onChange: PropTypes.func.isRequired
    }

    static defaultProps = {
        color: '#ff0000',
        labelwidth: '8',
        inputwidth: '4',
        label: 'Foo',
        name: 'foo',
    }

    constructor(props) {
        super(props);
        this.state = {
            color: this.props.color,
            displayColorPicker: false
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleChangeComplete = this.handleChangeComplete.bind(this);
    }

    handleClick = () => {
        // eslint-disable-next-line react/no-access-state-in-setstate
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    }

    handleClose = () => {
        this.setState({ displayColorPicker: false });
        this.props.onChange(this.state.color);
    };

    handleChangeComplete = color => {
        this.setState({ color: color.hex });
        // alert(color);
    };

    render() {
        return (
            <div className={styles.colorpicker}>
                <div className={styles.row}>
                    <Label {...this.props} />
                    <div className={styles['col-' + this.props.inputwidth]}>
                        <button className={styles.swatch} style={{ backgroundColor: this.state.color }} onClick={this.handleClick} />
                        { this.state.displayColorPicker
                            && (
                            <div className={styles.pickerHolder}>
                                <div className={styles.row}>
                                    <div className={styles['col-12'] + ' ' + styles.slider}>
                                        <SliderPicker
                                            color={this.state.color}
                                            onChangeComplete={this.handleChangeComplete}
                                        />
                                    </div>
                                    <div className={styles['col-12']}>
                                        <ButtonText caption="Ok" handleClick={this.handleClose} />
                                    </div>
                                </div>
                            </div>
)
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ColorPicker;
