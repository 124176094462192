import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import de from 'date-fns/locale/de';
import './datepicker/datepicker-cssmodules.scss';

registerLocale('de', de);

// eslint-disable-next-line react/prefer-stateless-function
class DatePicker extends React.Component {
    static defaultProps= {
        locale: 'de',
        dateFormat: de.formatLong.date({ width: 'short' })
    };

    render() {
        const { props } = this;
        return (
            <ReactDatePicker {...props} />
        );
    }
}

export default DatePicker;
