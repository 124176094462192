/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    useFormik, FormikHelpers, FormikContextType, FormikErrors
} from 'formik';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { TrendingUpTwoTone } from '@material-ui/icons';
import FormContainer from './FormContainer';
import { getYupSchemaFromMetaData } from './YupSchemaCreator';

export interface FormConfigValidations {
    type: 'required',
    params?: string[]
}

export interface FormConfigOption {
    value: string,
    label: string
}

export interface Field {
    from: string,
    to: string
}

export interface Label {
    from?: string,
    to?: string,
    minutes?: string,
    hours?: string
}

export interface Appearance {
    size?: 'small' | 'normal',
    suggestions?: boolean,
    showLabel?: boolean
}

export interface FormConfig {
    type: 'text' | 'number' | 'none' | 'array' | 'select' | 'autocomplete' | 'date' | 'dateFromTo' | 'time' | 'date_from_to' | 'date_singleday' | 'combobox' | 'multiselect' | 'tableselect' | 'multiselect_cols',
    appearance?: Appearance,
    field: Field | string,
    label: string | Label,
    defaultValue?: string,
    defaultOptions?: FormConfigOption[],
    dependOn?: string [],
    options?: FormConfigOption[] | ((formik:FormikContextType<any>) => FormConfigOption[] | Promise<FormConfigOption[]>),
    style?: React.CSSProperties,
    minutesPrecision?: number
    children?: FormConfig[]
    validationType?: 'string' | 'number',
    validations?: FormConfigValidations[]
}

interface UseForm {
    config?: FormConfig[],
    onSubmit?: any; // (values: {}, formikHelpers: FormikHelpers<{}>) => void | Promise<any>,
    initialValues?: any,
    validate?:((values: any) => void | object | Promise<FormikErrors<any>>) | undefined,
    onBlur?: (evt:any, aformik: ExtendFormik) => void
}

export interface ExtendFormik extends FormikContextType<any>{
    onBlur?: any,
    reload?: boolean
}

export const useForm = ({
    config, onSubmit, initialValues, validate, onBlur
}: UseForm) => {
    let validationSchema = {};
    if (config) {
        validationSchema = getYupSchemaFromMetaData(config, [], []);
    }

    const formik: ExtendFormik = useFormik({
        initialValues: initialValues || {},
        validateOnChange: true,
        // isInitialValid: false,
        // validateOnBlur: true,
        // validateOnMount: false,
        validate,
        onSubmit,
        validationSchema
    });
    formik.onBlur = (evt:any, aformik: ExtendFormik) => {
        aformik.reload = true;
        console.log('ON Blur', aformik.values, aformik.isValid);
        aformik.handleBlur(evt);
        if (onBlur) {
            onBlur(evt, aformik);
        }
    };
    useEffect(() => {
        if (formik.isSubmitting && !formik.isValidating) {
            // eslint-disable-next-line no-restricted-syntax
            for (const path of Object.keys(formik.errors)) {
                formik.setFieldTouched(path, true, false);
            }
        }
    }, [formik.errors, formik.isSubmitting, formik.isValidating, formik.setFieldTouched]);
    return formik;
};

interface OptionsReturn {
    loading: boolean,
    options: FormConfigOption[]
}

const checkReloadOptionsTouched = (formik:ExtendFormik, dependsOn: string[] | undefined): boolean => {
    let ret = true;
    if (dependsOn == null) {
        return ret;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const item of dependsOn) {
        if (formik.touched[item] == null || !formik.touched[item]) {
            ret = false;
            break;
        }
    }
    if (ret === true) {
        dependsOn.forEach(item => {
            formik.setFieldTouched(item, false, false);
        });
    }
    return ret;
};

const updateOptions = (config: FormConfig, formik: any, setLoading: any, setOptions: any) => {
    const { options, dependOn } = config;
    if (options != null) {
        console.log('OPTIONS ------------------------', options.constructor.name, options, options.constructor);
        if (typeof options === 'function') {
            if (checkReloadOptionsTouched(formik, dependOn)) {
                const fetchOptions = async () => {
                    setLoading(true);
                    if (options !== undefined && typeof options === 'function') {
                        setOptions(await options(formik));
                    }
                    setLoading(false);
                };
                fetchOptions();
            }
        } else {
            setOptions(options);
        }
    }
};

export const useOptions = (formik: ExtendFormik, config: FormConfig): OptionsReturn => {
    const [loading, setLoading] = useState(false);
    const [optionsState, setOptions] = useState<FormConfigOption[]>([]);
    const reloads: any = [];
    const { dependOn, options } = config;
    if (dependOn && dependOn.length > 0) {
        reloads.push(formik.isValidating);
    }
    // console.log('------------------_>reloading', formik);
    useEffect(() => {
        updateOptions(config, formik, setLoading, setOptions);
    }, reloads);
    return { loading, options: optionsState };
};

export interface FormProps {
    config: FormConfig[],
}

const Theme = createTheme();

const useStyles = makeStyles((theme: typeof Theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: '10px',
            width: '100%',
        },
    },
}));

const Form:FunctionComponent<FormProps> = ({ config }) => {
    const classes = useStyles();
    const formik = useForm({
        config,
        initialValues: {},
        onSubmit: (values: any) => {
            console.log(values);
        }
    });
    // console.log('errors', formik.errors);
    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <FormContainer config={config} form={formik} />
            <button type="submit">Submit</button>
        </form>
    );
};

export default Form;
