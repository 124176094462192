import React, { FunctionComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import deLocale from 'date-fns/locale/de';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import { FormikContextType } from 'formik';
import { FormConfig } from '../Form';

interface Props {
    config: FormConfig,
    formik: FormikContextType<any>,
}

const MyDate:FunctionComponent<Props> = ({ config, formik }) => {
    const field = config.field as string;
    const [value, setValue] = React.useState<Date | null>(null);
    const onChange = (date:any) => {
        console.log('onchange', date);
        formik.setFieldValue(field, date);
    };
    const hasError = formik.errors[field] !== undefined && formik.touched[field] !== undefined;
    const currentError = formik.errors[field];
    const currentValue = formik.values[field];
    if (currentValue === undefined) {
        formik.setFieldValue(field, new Date(), false);
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <DatePicker
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
                label={config.label}
                value={currentValue}
                InputAdornmentProps={{ position: 'start' }}
                onChange={onChange}
                renderInput={params => <TextField {...params} onBlur={formik.handleBlur} helperText={hasError ? currentError : ''} error={hasError} name={field} />}
            />
        </LocalizationProvider>
    );
};

export default MyDate;
