// eslint-disable-next-line no-unused-vars
import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';

// Polyfills for IE11
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import Ajax from './utils/Ajax';
import Auth from './utils/Auth';
import Globals from './utils/Globals';
import Bundle from './utils/Bundle';
import Download from './utils/Download';
import Table from './components/Table/Table';
import ButtonText from './components/Buttons/ButtonText/ButtonText';
import ButtonFilter from './components/Buttons/ButtonFilter/ButtonFilter';
import Button20IconCircle from './components/Buttons/Button20IconCircle/Button20IconCircle';
import Button22Icon from './components/Buttons/Button22Icon/Button22Icon';
import DocumentHead from './components/Document/DocumentHead';
import Document from './components/Document/Document';
import DocumentHr from './components/Document/DocumentHr';
import Spinner from './components/Spinner/Spinner';
import TableStandard from './components/TableStandard/TableStandard';
import TableOfContents from './components/TableOfContents/TableOfContents';
import TableOfContentsChapter from './components/TableOfContents/TableOfContentsChapter';
import ListContentHint from './components/ListContentHint/ListContentHint';
import Filter from './components/Filter/Filter';
import ModalDialog from './components/ModalDialog/ModalDialog';
import FormInput from './components/FormElements/FormInput';
import FormDatepicker from './components/FormElements/FormDatepicker';
import FormTimepicker from './components/FormElements/FormTimepicker';
import FormSelect from './components/FormElements/FormSelect';
import * as form from './form';
import * as ui from './ui';
import ColorPicker from './components/FormElements/ColorPicker';
import TableSelector from './components/FormElements/TableSelector/TableSelector';
import Help from './components/Help/Help';
import i18n from './i18n';
// import 'react-table/node_modules/@types/react';
// import Field from './components/FormElements/Field';
import Utils from './utils/Utils';

const CommonsInfo = {
    version: process.env.REACT_APP_VERSION,
    hash: process.env.REACT_APP_HASH,
    name: process.env.REACT_APP_NAME,
};

export * from './form';

export {
    CommonsInfo,
    ui,
    form,
    i18n,
    withTranslation,
    Ajax,
    Auth,
    Bundle,
    Globals,
    Download,
    ButtonText,
    ButtonFilter,
    Button20IconCircle,
    Button22Icon,
    DocumentHead,
    Document,
    DocumentHr,
    Spinner,
    Table,
    TableStandard,
    TableOfContents,
    TableOfContentsChapter,
    ListContentHint,
    Filter,
    ModalDialog,
    FormInput,
    FormDatepicker,
    FormTimepicker,
    FormSelect,
    ColorPicker,
    TableSelector,
    ReactTable,
    // Field,
    moment,
    Utils,
    Help,
    _,
};
