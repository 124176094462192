/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useCallback } from 'react';

import { styled } from '@mui/material/styles';
import {
    Autocomplete, IconButton, Stack, Tooltip, Menu, MenuItem, Select, FormControl, TextField, Typography
} from '@mui/material';
import { FilterListRounded, Clear } from '@mui/icons-material';
import { DatePickerFromTo } from '../Form/Fields';
import { FormConfig, ExtendFormik, useForm } from '../Form/Form';
import FilterItem from './FilterItem';

// Behalten wird für APiData Fetch benötigt.
// import interfaces from '../../../../api/interfaces';
// import runs from '../../../../api/runs';

export interface FilterBarnProps {
    apiDataName: any;
}

const StyledDiv = styled('div')`
  width: 100%;
  background: #ff0000;
  min-height:40px;
  border-radius: 3px;
  background: #EEEEEE;
  border:solid 1px #CCCCCC;
`;

const StyledStack = styled(Stack)(() => `
    align-items: center;
    justify-content: flex-end;
    min-height:38px;
  `);

const StyledIconButton = styled(IconButton)(({ theme }) => `
    color: ${theme.palette.text.primary};
    & :hover {
        color: ${theme.palette.secondary.main};
    };
    & :active {
        color: ${theme.palette.primary.main};
    };
  `);

const options = [
    { id: 0, title: 'Datum', type: 'date' },
    {
        id: 1,
        title: 'User',
        type: 'select',
        values: [
            {
                label: 'Klaus',
                value: '1'
            },
            {
                label: 'Peter',
                value: '2'
            },
            {
                label: 'Uschi',
                value: '3'
            }
        ]
    },
    {
        id: 2,
        title: 'Status',
        type: 'select',
        values: [
            {
                label: 'Tiptop',
                value: '1'
            },
            {
                label: 'Alles doof',
                value: '2'
            }
        ]
    },
    { id: 3, title: 'Suche', type: 'search' }
];

const FilterBar = (props: FilterBarnProps) => {
    const { apiDataName } = props; // Damit nicht überall props. geschrieben werden muss.

    const [data, setData] = React.useState<any>([]);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selected, setSelected] = React.useState<any[]>([]); // Welche und ob TabellenZeilen ausgewählt sind
    const open = Boolean(anchorEl);

    const fetchData = useCallback(async () => {
        let raceOf = true;

        // get the data from the api
        const resp = await apiDataName.list({ page: 0, pageSize: 1000 });

        if (raceOf) {
            // set state with the result
            setData(resp.data);
        }

        // cancel any future `setData`
        return () => { raceOf = false; };
    }, []);

    // gets the Data every time
    useEffect(() => {
        // call the fetch function getData
        fetchData()
        // make sure to catch any error
            .catch(console.error);
    }, [fetchData]);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event: React.MouseEvent<unknown>, option: any) => {
        const newSelected = selected;
        newSelected.push(option);

        setSelected(newSelected);
        setAnchorEl(null);
    };

    const isSelected = (id: number) => {
        let isAlreadySelected: boolean = true;
        selected.forEach(value => {
            if (value.id === id) {
                isAlreadySelected = false;
            }
        });
        return isAlreadySelected;
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = (
        id: string,
    ) => {
        const newSelected: Array<any> = [];
        selected.forEach(value => {
            if (value.id !== id) {
                newSelected.push(value);
            }
        });
        setSelected(newSelected);
    };

    const config: FormConfig[] = [{
        type: 'dateFromTo',
        appearance: {
            size: 'small',
            suggestions: false,
            showLabel: false
        },
        field: {
            from: 'dateFrom',
            to: 'dateTo'
        },
        label: {
            from: 'DateFrom',
            to: 'DateTo'
        },
        validationType: 'string',
        validations: [{
            type: 'required',
            params: ['Date is required']
        }]

    }];
    const formik = useForm({
        config,
        initialValues: {},
        onSubmit: (values: any) => {
            console.log(values);
        }
    });

    const filterField = (elem: any) => {
        switch (elem.type) {
                        case 'date':
                            return (
                                <FormControl size="small">

                                    <Stack
                                        direction="row"
                                        spacing={1}
                                    >

                                        <DatePickerFromTo config={config[0]} formik={formik} />
                                    </Stack>

                                </FormControl>
                            );
                        case 'search':
                            return (
                                <FormControl size="small">
                                    <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        disableClearable
                                        options={data.map((option: any) => (option.title ? option.title : option.id))}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                sx={{ minWidth: '50px' }}
                                                size="small"
                                                label="Suche"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            );
                        default:
                            return (
                                <FormControl size="small">
                                    <Select
                                        id={elem.title}
                                        autoWidth
                                        onChange={() => { }}
                                        displayEmpty
                                        value=""
                                    >
                                        <MenuItem value="">
                                            <em>{elem.title}</em>
                                        </MenuItem>
                                        {elem.values.map((val: any) => (
                                            <MenuItem value={val.value}>{val.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
        }
    };

    return (
        <StyledDiv>
            {/* Box geht auch */}
            <StyledStack
                direction="row"
                aria-label="Device settings"
            >
                <Stack
                    direction="row"
                    spacing={3}
                >
                    {selected.map((select: any) => (

                        <FilterItem onClick={() => handleDelete(select.id)}>
                            {filterField(select)}
                        </FilterItem>


                    ))}
                </Stack>
                <Tooltip title="Filter">
                    <StyledIconButton
                        id="filterButton"
                        onClick={handleClickListItem}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'filterButtonMenu' : undefined}
                        aria-haspopup="true"
                        aria-label="filterButton"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <FilterListRounded />
                    </StyledIconButton>
                </Tooltip>
            </StyledStack>
            <Menu
                id="filterMenu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'filterButton',
                    role: 'listbox',
                }}
            >
                {options.map(option => {
                    if (isSelected(option.id)) {
                        return (
                            <MenuItem
                                key={option.id}
                                onClick={event => handleMenuItemClick(event, option)}
                            >
                                {option.title}
                            </MenuItem>
                        );
                    }
                    return false;
                })}
            </Menu>
        </StyledDiv>
    );
};

export default FilterBar;
