import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import i18n from 'i18next';
import styles from './spinner.module.scss';


class Spinner extends React.Component {
    static propTypes = {
        mode: PropTypes.string,
        text: PropTypes.string,
        append: PropTypes.string,
        load: PropTypes.bool,
        id: PropTypes.string
    };

    static defaultProps = {
        mode: 's',
        text: undefined,
        append: '...',
        load: false,
        id: null,
    }

    constructor(props) {
        super(props);
        this.state = {
            show: props.load,
            text: props.text ? props.text : i18n.t('spinner.loading')
        };
    }

    getInitialState() {
        return { show: 'none' };
    }

    componentDidMount() {
        this.updateProps(this.props);
        // const { text } = this.state;
        // if (text === '[spinner.loading]') {
        // }
    }

    componentWillReceiveProps(nextProps) {
        this.updateProps(nextProps);
        console.log('receive props');
    }

    updateProps(props) {
        const newState = {
            show: 'none'
        };
        if (props.load) {
            newState.show = 'block';
        }
        if (props.text) {
            newState.text = props.text;
        }
        this.setState(newState);
    }

    render() {
        const uuid = uuidv4();
        let id = 'spinner-' + uuid;
        if (this.props.id) {
            id = 'spinner-' + this.props.id + '-' + uuid;
        }
        return (
            <div id={id} className={styles.reactSpinner + ' ' + styles[this.props.mode]} style={{ display: this.state.show }}>
                <div className={styles.reactSpinnerInner}>
                    <div className={styles.reactSpinnerImage} />
                    <div className={styles.reactSpinnerStatus}>
                        {this.state.text}
                        {this.props.append}
                    </div>
                </div>
            </div>
        );
    }
}

export default Spinner;
