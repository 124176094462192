import React, { FunctionComponent, ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import { FormConfig, ExtendFormik } from '../Form';


interface Props {
    config: FormConfig,
    formik: ExtendFormik,
}

const Text:FunctionComponent<Props> = ({ config, formik }) => {
    const field = config.field as string;
    const hasError = formik.errors[field] !== undefined && formik.touched[field] !== undefined;
    const onChange = (evt:ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(evt);
    };
    const onBlur = (evt:any) => {
        formik.onBlur(evt, formik);
    };
    return (
        <TextField
            error={hasError}
            helperText={hasError ? formik.errors[field] : ''}
            label={config.label}
            name={field}
            value={formik.values ? formik.values[field] : undefined}
            defaultValue={config.defaultValue}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
};

export default Text;
