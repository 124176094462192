/* eslint-disable react/require-default-props */
/* eslint-disable react/no-danger */
// import useState next to FunctionComponent
import React, { useState, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import i18n from 'i18next';
import Delegate from '../../utils/Delegate';
import styles from './Help.module.scss';

let listenerFunc: any = null;
let iconText: string = '';
let fromOver: boolean = false;
let currentText = '';
const dd = new Delegate();

const Help: React.FunctionComponent<{ open?: boolean, text?: string }> = ({ open = false, text = 'general.help.helptext' }) => {
    const [checked, setChecked] = useState(open);
    const [htext, setHText] = useState(text);

    const setText = async (aText: string, from: boolean) => {
        if (aText !== currentText) {
            fromOver = from;
        }
        currentText = aText;
        if (aText && typeof aText === 'string') {
            if (aText !== '') {
                if (i18n.exists(aText)) {
                    aText = i18n.t(aText);
                }
            } else {
                aText = 'general.help.helptext';
            }
            setHText(aText);
        }
    };

    const setMouseEnter = (what: boolean) => {
        /* const all = document.querySelectorAll('[data-help]');
        all.forEach((ele: Element) => {
            if (what) {
                ele.addEventListener('mouseenter', listenerFunc, false);
            } else {
                ele.removeEventListener('mouseenter', listenerFunc, false);
            }
        }); */
        if (what) {
            dd.root(document.body);
            dd.on('mouseenter', '[data-help]', listenerFunc, true);
        } else {
            dd.destroy();
        }
    };

    const enable = async (what: boolean) => {
        setMouseEnter(what);
        await setChecked(what);
        await setText('general.help.helptext', true);
        fromOver = true;
    };

    useEffect(() => {
        listenerFunc = async (evt2: any) => {
            // text = evt2.target.dataset.help;
            // console.log('------------->listen', evt2, evt2.target.dataset.help);
            const atext = evt2.target.dataset.help;
            if (atext !== undefined) {
                await setText(atext, true);
            }
        };
        iconText = i18n.t('general.help.icon');
    }, []);

    useEffect(() => {
        enable(open);
        fromOver = true;
    }, [open]);

    useEffect(() => {
        if (open) {
            if (!fromOver) {
                setText(text, false);
            } else {
                fromOver = false;
            }
        }
    });

    return (
        <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
            <div className={styles.help}>
                <div className={styles.icon}>{iconText}</div>
                <p dangerouslySetInnerHTML={{ __html: htext }} />
            </div>
        </Slide>
    );
};

export default Help;
