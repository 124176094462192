/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button22Icon.module.scss';

class Button22Icon extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        caption: PropTypes.string,
        icon: PropTypes.string,
        handleClick: PropTypes.func
    }

    static defaultProps = {
        id: null,
        caption: 'minus',
        icon: 'minus',
        handleClick: () => alert('Clicked!')
    }

    constructor(props) {
        super(props);
        this.state = {
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        console.log(e);
        e.stopPropagation();
        this.props.handleClick();
    }

    render() {
        return (
            <button id={this.props.id} className={styles.Button22Icon + ' ' + styles[this.props.icon]} title={this.props.caption} onClick={e => this.handleClick(e)}>
                {this.props.caption}
            </button>
        );
    }
}

export default Button22Icon;
