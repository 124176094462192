import React, { FunctionComponent } from 'react';
import {
    IconButton, Stack
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import DeleteFilterButton from './DeleteFilterButton';

interface Props {
    onClick: () => void,
    children: any,
    // config: FormConfig,
    // formik: FormikContextType<any>,
    // size?: string
}

const FilterItem: FunctionComponent<Props> = ({ onClick, children }) => {
    console.log('AAA');
    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0}>
            {children}
            <DeleteFilterButton onClick={onClick} />
        </Stack>
    );
};

export default FilterItem;
