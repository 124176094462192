/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

import Spinner from '../Spinner/Spinner';
import TableOfContentsChapter from './TableOfContentsChapter';

function dateConverter(value) {
    const output = value;
    return output;
}

interface Props {
    id: string,
    data: any[],
    rows: { id, h1, h2 },
    handleClick: () => void,
    selectedIndex: number
}

function TableOfContents({
    id, handleClick, data, rows, selectedIndex
}: Props) {
    // Translation Hook from react-il8next
    const [t, i18n] = useTranslation();
    // Hook / State für die Listen-Einträge
    const [items, setItems] = useState(data);
    // Hook / State in dem die value aus dem Suchfeld gespeichert wird
    const [SearchString, setSearchString] = useState('');
    // Const in der die parameter zur Suche gespeichert sind
    const searchParam = [rows.id, rows.h1, rows.h2];

    // Hook fetcht async und erwartet die daten nimmt sie und packt sie als result in den Hook setItems
    useEffect(() => {
        setItems(data);
    }, [data]);

    // Such Function - es werden alle Listeneinträge genommen und gefiltert,
    // und zwar nach den Parametern und dem value des Suchfelds
    function search(items) {
        return items.filter(item => searchParam.some(newItem => (
            item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(SearchString.toLowerCase()) > -1
        )));
    }

    return (
        <TableofContents className="TableOfContents" id={id}>
            <SortHolder className="sortHolder">
                <Label>{t('tableOfContents.sortBar.description')}</Label>
                <input
                    type="search"
                    value={SearchString}
                    onChange={e => setSearchString(e.target.value)}
                    className="filterSelect order"
                    placeholder={t('tableOfContents.searchPlaceholder')}
                />
            </SortHolder>
            <ChapterHolder className="chapterHolder">
                <div>
                    {items && items === null
                        ? <Spinner id="dashboardHolder" mode="s" load text="Lade ..." />
                        : items && items.length > 0 ? (
                            search(items).map(row => (
                                <TableOfContentsChapter
                                    key={row.id}
                                    selected={selectedIndex === row.id}
                                    handleClick={handleClick}
                                    id={row[rows.id]}
                                    h1={row[rows.h1]}
                                    h2={dateConverter(row[rows.h2])}
                                />
                            ))
                        ) : (
                            <div>{t('tableOfContents.nothingFound')}</div>
                        )}
                </div>
            </ChapterHolder>
        </TableofContents>
    );
}

const TableofContents = styled('div')`
    display: grid;
    grid-template-rows: 60px 1fr;
`;

const Label = styled('div')`
    display: inline-block;
    height: 20px;
    line-height: 20px;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
`;

const SortHolder = styled('div')`
    display: grid;
    grid-template-columns: 60px 1fr;
    align-items: center;
    align-content: center;
    background: rgba(255, 255, 255, 0.9);
    height: 30px;
`;

const ChapterHolder = styled('div')`
    padding-top: 0px;
`;

export default TableOfContents;
