import Globals from './Globals';

class Auth {
    static setToken(token, remember) {
        localStorage.token = token;
        localStorage.tokenRemember = remember;
        const now = new Date().getTime();
        localStorage.tokenCreated = now;
        localStorage.tokenUpdated = now;
    }

    static getHeaderName() {
        return 'X-Authorisation';
    }

    static getToken() {
        const { token, tokenUpdated, tokenRemember } = localStorage;
        if (!token) {
            this.logout();
            return '';
        }
        const now = new Date().getTime();
        const secondsDiv = (now - tokenUpdated) / 1000;
        if (tokenRemember && secondsDiv > (236 * 60 * 60)) {
            this.logout();
            return '';
        }
        if (secondsDiv > (12 * 60 * 60)) {
            this.logout();
            return '';
        }
        localStorage.token_updated = now;
        return token;
    }

    static getLang() {
        return localStorage.userLang;
    }

    static setLang(lang) {
        localStorage.userLang = lang;
    }

    static logout() {
        Globals.user = null;
        delete localStorage.token;
        delete localStorage.tokenRemember;
        delete localStorage.tokenCreated;
        delete localStorage.tokenUpdated;
    }

    static loggedIn() {
        return this.getToken() !== '';
    }

    // onChange() {}
}

export default Auth;
