import {
    Select,
    FormControl,
    FormHelperText,
    MenuItem,
    InputLabel,
    Button,
    Link,
    Step,
    StepButton,
    StepConnector,
    StepContent,
    StepIcon,
    StepLabel,
    Stepper,
    Modal,
    Typography,
    CircularProgress,
    Backdrop,
    CssBaseline,
    StyledEngineProvider,
    createTheme,
} from '@material-ui/core';
import {
    makeStyles,
    ThemeProvider
} from '@material-ui/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/open-sans';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { DateFromTo, DatePickerFromTo } from './components/Form/Fields';
import FilterBar from './components/FilterBar/FilterBar';
import * as Form from './components/Form/Form';

const theme = createTheme({
    typography: {
        // fontFamily: 'Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma',
        htmlFontSize: 14, // 16 default
        fontSize: 14, // 14 default
        fontFamily: 'Open Sans',
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#01aad3',
            // dark: will be calculated from palette.primary.main,
            contrastText: '#fff'
        },
        secondary: {
            main: '#0a193a',
            contrastText: '#fff'
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        // mode: 'dark',
        background: {
            default: '#fff'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: { margin: '20px' },
            },
        },
    },
});

const icons = {
    HelpOutlineIcon
};

export {
    Select,
    ThemeProvider,
    FormControl,
    FormHelperText,
    MenuItem,
    CircularProgress,
    Backdrop,
    Button,
    Link,
    InputLabel,
    makeStyles,
    createTheme,
    CssBaseline,
    theme,
    icons,
    Step,
    StepButton,
    StepConnector,
    StepContent,
    StepIcon,
    StepLabel,
    Stepper,
    Modal,
    Typography,
    StyledEngineProvider,
    DateFromTo,
    DatePickerFromTo,
    Form,
    FilterBar
};
