import React, {
    FunctionComponent, ChangeEvent, useState, useEffect
} from 'react';
import { FormikContextType } from 'formik';
import {
    InputLabel, TextField, CircularProgress, MenuItem, Theme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FormConfig, FormConfigOption, useOptions } from '../Form';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

interface Props {
    config: FormConfig,
    formik: FormikContextType<any>,
}

const MySelect: FunctionComponent<Props> = ({ config, formik }) => {
    const field = config.field as string;
    const classes = useStyles();
    const hasError = formik.errors[field] !== undefined && formik.touched[field] !== undefined;
    if (config.options == null) {
        config.options = [];
    }
    const { loading, options } = useOptions(formik, config);
    const optionBuilder = (option: FormConfigOption, index: number) => <MenuItem key={index} value={option.value}>{option.label} </MenuItem>;
    const onChange = (evt: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        config.defaultOptions = options;
        formik.handleChange(evt);
    };
    return (
        <FormControl className={classes.formControl} variant="outlined" error={hasError}>
            {!loading
                ? (
                    <>
                        <TextField
                            label={config.label}
                            id={field}
                            name={field}
                            value={formik.values[field] || ''}
                            onBlur={formik.handleBlur}
                            onChange={onChange}
                            select
                        >
                            {options ? options.map((c, index) => optionBuilder(c, index)) : null}
                        </TextField>
                        { hasError
                            && <FormHelperText>{formik.errors[field]}</FormHelperText>
                        }
                    </>
                )
                : <CircularProgress />
            }
        </FormControl>
    );
};

export default MySelect;
