import validator from 'validator';
import _ from 'underscore';
import i18n from 'i18next';

/* interface IValidators {
    validate: (value: string, myvalidators: IValidators) => { validationErrors:string; validated: boolean;};
    [key: string]: (value: string) => boolean | string;
} */

// NOT NEEDED, Look at the validator libary
/**
 * Method checks if validators contain MIN/MAX values and whether an editor cell value exceeds these limits
 * @param value Value in an Editor cell
 * @param validators Array of validators (e.g. "required", "int", "{ MIN: 1000, MAX: 1000}")
 * @returns String that contains validation error id in case of validation error or empty string otherwise
 */
const numberRangeValidation = (value: string, validators) => {
    let validationError = '';

    if (validators !== undefined) {
        // tries to find object that contains range values (e.g. { MIN: 1000, MAX: 1000} )
        const rangeObj = validators.find(el => el.MAX !== undefined || el.MIN !== undefined);

        if (rangeObj !== undefined) {
            const floatValue = Number.parseFloat(value);
            const { MAX } = rangeObj;
            const { MIN } = rangeObj;

            if (MAX !== undefined && floatValue > MAX) {
                validationError = 'validator.number.too.big';
            }

            if (MIN !== undefined && floatValue < MIN) {
                validationError = 'validator.number.too.small';
            }
        }
    }

    return validationError;
};


const Validators = {
    required: (value: string) => {
        if (value === undefined || value == null || !value.toString().trim().length) {
            return i18n.t('validator.required') as string;
        }
        return false;
    },
    email: (value: string) => {
        if (!validator.isEmail(value)) {
            return '"' + value + '" ' + i18n.t('validator.email');
        }
        return false;
    },
    isfloat: (value: string) => {
        if (!validator.isFloat(value, { locale: 'de-DE' })) {
            return '"' + value + '" ' + i18n.t('validator.float');
        }
        return false;
    },
    float: (value: string) => Validators.isfloat(value),
    int: (value: any) => {
        if (!validator.isInt(value + '')) {
            return '"' + value + '" ' + i18n.t('validator.float');
        }
        return false;
    },
    validate(value: string, myvalidators: unknown[]) {
        let validationErrors = '';
        const anyValidator = validator as any;

        // validationErrors = numberRangeValidation(value, myvalidators);

        if (myvalidators) {
            myvalidators.forEach(myvalidator => {
                const anyMyValidator = myvalidator as any;
                let validated = true;
                let validationName = myvalidator;
                let validatedMessage = false;
                const validationVars = {};
                let validatorOptions = [];
                if (_.isString(myvalidator)) {
                    validatorOptions.push(myvalidator);
                } else {
                    validatorOptions = myvalidator as any[];
                }
                if (validatorOptions[0] !== undefined) {
                    let validatorName = validatorOptions[0];
                    validatorName = validatorName === 'regexp' || validatorName === 'regex' ? 'matches' : validatorName;
                    const stringValue = value + '';
                    if (validatorName !== 'required' && Validators.required(value)) {
                        return;
                    }
                    if ((Validators as any)[validatorName] !== undefined) {
                        validatedMessage = (Validators as any)[validatorName](stringValue);
                        if (validatedMessage) {
                            validated = false;
                        }
                    } else {
                        if (anyValidator[validatorName] === undefined) {
                            validatorName = 'is' + validatorName.charAt(0).toUpperCase() + validatorName.slice(1);
                        }
                        if (anyValidator[validatorName] !== undefined) {
                            const params = [stringValue, ...validatorOptions.slice(1)];
                            validated = anyValidator[validatorName](...params);
                            validationName = validatorName;
                        }
                    }
                }
                if (!validated) {
                    if (validationErrors !== '') {
                        validationErrors += '<br />';
                    }
                    validationErrors += validatedMessage || ' ' + i18n.t('validator.' + validationName, validationVars);
                }
            });
        }
        return {
            validationErrors,
            validated: validationErrors === ''
        };
    }
};

export default Validators;
