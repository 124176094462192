import React, { FunctionComponent } from 'react';
import deLocale from 'date-fns/locale/de';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import TextField from '@material-ui/core/TextField';
import i18n from 'i18next';
import { FormikContextType } from 'formik';
import { FormConfig } from '../Form';

interface Props {
    config: FormConfig,
    formik: FormikContextType<any>,
    size?: string
}

const MyDate: FunctionComponent<Props> = ({ config, formik }) => {
    const fieldFrom = typeof config.field === 'string' ? config.field + 'From' : config.field.from;
    const fieldTo = typeof config.field === 'string' ? config.field + 'To' : config.field.to;
    const labelFrom = typeof config.label === 'string' ? config.field + 'From' : config.label.from;
    const labelTo = typeof config.label === 'string' ? config.field + 'To' : config.label.to;
    const hasErrorFrom = formik.errors[fieldFrom] !== undefined && formik.touched[fieldFrom] !== undefined;
    const hasErrorTo = formik.errors[fieldTo] !== undefined && formik.touched[fieldTo] !== undefined;
    const onChangeFrom = (date: any) => {
        formik.setFieldValue(fieldFrom, date);
    };
    const onChangeTo = (date: any) => {
        formik.setFieldValue(fieldTo, date);
    };
    const currentErrorFrom = formik.errors[fieldFrom];
    const currentErrorTo = formik.errors[fieldTo];
    const currentValueFrom = formik.values[fieldFrom];
    const currentValueTo = formik.values[fieldTo];
    if (currentValueFrom === undefined) {
        formik.setFieldValue(fieldFrom, new Date());
    }
    if (currentValueTo === undefined) {
        formik.setFieldValue(fieldTo, new Date());
    }
    if (currentValueFrom > currentValueTo) {
        formik.setFieldValue(fieldTo, currentValueFrom);
    }
    const { size, showLabel, suggestions } = config.appearance;
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <DatePicker
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
                label={showLabel ? labelFrom : false}
                value={currentValueFrom}
                InputAdornmentProps={{ position: 'start' }}
                renderInput={(params:any) => <TextField {...params} size={size} onBlur={formik.handleBlur} helperText={hasErrorFrom ? currentErrorFrom : ''} error={hasErrorFrom} name={fieldFrom} />}
                onChange={onChangeFrom}
            />
            <DatePicker
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
                minDate={currentValueFrom}
                label={showLabel ? labelTo : false}
                value={currentValueTo}
                InputAdornmentProps={{ position: 'start' }}
                renderInput={(params:any) => <TextField {...params} size={size} onBlur={formik.handleBlur} helperText={hasErrorTo ? currentErrorTo : ''} error={hasErrorTo} name={fieldTo} />}
                onChange={onChangeTo}
            />
        </LocalizationProvider>
    );
};

export default MyDate;
