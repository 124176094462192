import React from 'react';
import PropTypes from 'prop-types';
import styles from './ValidationError.module.scss';

class FormInput extends React.Component {
    static propTypes = {
        validationError: PropTypes.string,
        mode: PropTypes.string
    }

    static defaultProps = {
        validationError: null,
        mode: 'default'
    }

    constructor(props) {
        super(props);
        this.state = {
            validationError: props.validationError
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            validationError: newProps.validationError
        });
    }

    render() {
        const { validationError } = this.state;
        if (validationError && validationError !== '') {
            return (
                <div className={styles.validationError + ' ' + styles[this.props.mode]}>
                    {this.props.mode === 'bubble' && <div className={styles.arrow} /> }
                    <div className={styles.label}>
                        {validationError}
                    </div>
                </div>
            );
        }
        return null;
    }
}

export default FormInput;
