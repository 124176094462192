import React from 'react';
import PropTypes from 'prop-types';
import ButtonText from '../Buttons/ButtonText/ButtonText';
import styles from './DocumentHead.module.scss';

export default class DocumentHead extends React.Component {
    static propTypes = {
        h1: PropTypes.string,
        h2: PropTypes.string,
        buttons: PropTypes.array,
        onChange: PropTypes.func,
        editable: PropTypes.bool,
        disabled: PropTypes.bool,
        icon: PropTypes.node
    };

    static defaultProps = {
        h1: 'Headline',
        h2: 'Subhead',
        buttons: [],
        onChange() { return false; },
        editable: false,
        disabled: false,
        icon: false
    };

    constructor(props) {
        super(props);
        this.state = {
            h1: props.h1,
            h2: props.h2,
            disabled: props.disabled
        };
        this.handleChange = this.handleChange.bind(this);
        this.focusOutReady = this.focusOutReady.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.disabled !== nextProps.disabled) {
            this.setState({
                disabled: nextProps.disabled
            });
        }
        if (nextProps !== this.props) {
            this.setState({
                h1: nextProps.h1,
                h2: nextProps.h2
            });
        }
    }

    handleChange(event, elem) {
        if (elem === 'h1') {
            this.setState({ h1: event.target.value });
        } else {
            this.setState({ h2: event.target.value });
        }
    }

    focusOutReady(event, elem) {
        this.props.onChange(event.target.value, elem);
    }

    render() {
        const that = this;
        const buttons = this.props.buttons.map(item => {
            let { disabled } = that.state;
            if (item.disabled) {
                disabled = true;
            }
            return (
                <ButtonText id={item.id} caption={item.caption} key={item.caption} disabled={disabled} handleClick={item.handleClick} />
            );
        });
        const stylesIcon = {
            backgroundImage: 'url("' + this.props.icon + '")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left top',
            backgroundSize: '50px 50px',
            minHeight: '50px',
        };
        return (
            <div className={'document_head ' + styles.DocumentHeadline}>
                {this.props.editable ? (
                    <div className={styles.healines} style={stylesIcon}>
                        <h1><input type="text" value={this.state.h1} size={this.state.h1.length + 1} maxLength="59" onChange={e => { this.handleChange(e, 'h1'); }} onBlur={e => { this.focusOutReady(e, 'h1'); }} /></h1>
                        <h2><input type="text" value={this.state.h2} size={this.state.h2.length + 1} maxLength="59" onChange={e => { this.handleChange(e, 'h2'); }} onBlur={e => { this.focusOutReady(e, 'h2'); }} /></h2>
                    </div>
                ) : (
                    <div className={styles.healines} style={stylesIcon}>
                        <h1>{this.state.h1}</h1>
                        <h2>{this.state.h2}</h2>
                    </div>
                )}
                <div className={styles.buttonHolder}>
                    {buttons}
                </div>
                <div className={styles.clearBoth} />
            </div>
        );
    }
}
