import * as yup from 'yup';
import { FormConfig } from './Form';
import YupLocale from './YupLocale';

/** Adding just additional methods here */

yup.setLocale(YupLocale);

yup.addMethod(yup.string, 'URL', function addMethod(...args) {
    return this.url(...args);
});


yup.addMethod(yup.string, 'stringBoolean', function fValidator(message: string) {
    return this.test('is-string-boolean', message, value => {
        if (value === '') {
            return true;
        }

        if (value !== undefined && ['Y', 'N'].indexOf(value) !== -1) {
            return true;
        }
        return false;
    });
});

export const createYupSchema = (schema: any, config: FormConfig) => {
    const {
        field, type, validationType, label, validations = []
    } = config;
    let valType = validationType;
    if (valType === undefined) {
        valType = 'string';
        if (type === 'number') {
            valType = type;
        }
    }
    if (!yup[valType]) {
        return schema;
    }
    let validator = yup[valType]().nullable();
    validations.forEach(validation => {
        const { params, type: vtype } = validation;
        if (!validator[vtype]) {
            return;
        }
        if (params) {
            validator = validator[vtype](...params);
        } else {
            validator = validator[vtype]();
        }
    });

    if (typeof field === 'string') {
        if (typeof label === 'string') {
            validator = validator.label(label);
        }
        if (field.indexOf('.') !== -1) {
        // nested fields are not covered
        } else {
            schema[field] = validator;
        }
    } else {
        let validatorFrom = validator;
        if (typeof label === 'object') {
            validatorFrom = validatorFrom.label(label.from);
            validator = validator.label(label.to);
        }
        schema[field.from] = validatorFrom;
        schema[field.to] = validator;
    }

    return schema;
};

export const getYupSchemaFromMetaData = (
    config: FormConfig[],
    additionalValidations: {},
    forceRemove: string[]
) => {
    const yepSchema = config.reduce(createYupSchema, {});
    const mergedSchema = {
        ...yepSchema,
        ...additionalValidations,
    };

    forceRemove.forEach(field => {
        delete mergedSchema[field];
    });

    const validateSchema = yup.object().shape(mergedSchema);

    return validateSchema;
};
