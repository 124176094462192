import React, { FunctionComponent, ChangeEvent } from 'react';
import { FormikContextType } from 'formik';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/core/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { FormConfig, FormConfigOption } from '../Form';

interface Props {
    config: FormConfig,
    formik: FormikContextType<any>,
}

const useStyles = makeStyles((theme: Theme) => ({
    fields: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    bold: {
        paddingLeft: '10px',
        fontWeight: 'bold'
    }
}));

const TimeSelect:FunctionComponent<Props> = ({ config, formik }) => {
    const field = config.field as string;
    const minutesPrecision = config.minutesPrecision || 10;
    const hasError = formik.errors[field] !== undefined && formik.touched[field] !== undefined;
    const labelHours = typeof config.label === 'string' ? config.field + ' Hours' : config.label.hours;
    const labelMinutes = typeof config.label === 'string' ? config.field + ' Minutes' : config.label.minutes;
    const classes = useStyles();
    const currentError = formik.errors[field];
    const currentValue = formik.values[field] || '';
    const endHour = 30;
    const getHours = (): string => {
        const svar = currentValue.split(':');
        return svar[0] || '';
    };
    const getMinutes = (): string => {
        const svar = currentValue.split(':');
        return svar[1] || '';
    };
    const onChangeHours = (evt:ChangeEvent<{}>, value: FormConfigOption | null) => {
        formik.setFieldValue(field, value + ':' + getMinutes());
    };
    const onChangeMinutes = (evt:ChangeEvent<{}>, value: FormConfigOption | null) => {
        formik.setFieldValue(field, getHours() + ':' + value);
    };

    const generateHours = () => {
        const options = [];
        for (let i = 0; i <= endHour; i += 1) {
            const val = ('00' + i).slice(-2);
            options.push({
                value: val,
                label: val
            });
        }
        return options;
    };

    const generateMinutes = () => {
        const options = [];
        const max = (60 / minutesPrecision);
        for (let i = 0; i <= max; i += 1) {
            const val = ('00' + (i * minutesPrecision)).slice(-2);
            options.push({
                value: val,
                label: val
            });
        }
        return options;
    };
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option:any) => option.label,
    });

    return (
        <div className={classes.fields}>
            <Autocomplete
                options={generateHours()}
                filterOptions={filterOptions}
                getOptionLabel={(option: FormConfigOption) => option.label}
                onChange={onChangeHours}
                onBlur={formik.handleBlur}
                renderInput={(params:any) => (
                    <TextField
                        error={hasError}
                        helperText={hasError ? formik.errors[field] : ''}
                        name={config.field}
                        defaultValue={config.defaultValue}
                        label={labelHours}
                        variant="outlined"
                        {...params}
                    />
                )}
            />
            <span className={classes.bold}>:</span>
            <Autocomplete
                options={generateMinutes()}
                getOptionLabel={(option: FormConfigOption) => option.label}
                onChange={onChangeMinutes}
                onBlur={formik.handleBlur}
                renderInput={(params:any) => (
                    <TextField
                        error={hasError}
                        helperText={hasError ? formik.errors[field] : ''}
                        name={config.field}
                        defaultValue={config.defaultValue}
                        label={labelMinutes}
                        variant="outlined"
                        {...params}
                    />
                )}
            />
        </div>
    );
};

export default TimeSelect;
